import * as React from "react";
import { css } from "emotion";

export const EpisodeNumber: React.SFC<{}> = ({ children }) => (
  <div
    className={css`
      font-family: Oswald;
      font-size: 48px;
      color: #977535;
      font-weight: 300;
      letter-spacing: 0;
    `}
  >
    {children}
  </div>
);
