import * as React from "react";
import { css } from "emotion";

import { rhythm } from "../utils/typography";

export const Footer: React.SFC<{}> = () => (
  <div
    style={{
      padding: rhythm(1),
      width: "100%",
      backgroundColor: "#333333",
      display: "flex"
    }}
  >
    <div
      className={css`
        text-align: center;
        @media only screen and (max-width: 768px) {
          max-width: 230px;
          flex-direction: column;
        }
      `}
      style={{
        width: "100vw",
        maxWidth: 940,
        margin: "auto",
        display: "flex",
        color: "#B3B3B3",
        fontSize: 14,
        alignItems: "center"
      }}
    >
      <div
        className={css`
          display: flex;
          align-items: center;
          @media only screen and (max-width: 768px) {
            max-width: 230px;
            margin-bottom: ${rhythm(1)};
          }
        `}
      >
        <p style={{ marginBottom: 0 }}>
          © 2018 Awad Development, LLC. All Rights Reserved.
        </p>
      </div>
      <div style={{ flex: 1 }} />
      {/* <div
        style={{
          display: "flex",
          color: "#899EA3"
        }}
      >
        <div>Privacy Policy</div>
        <div style={{ marginLeft: rhythm(1.25) }}>Terms and Conditions</div>
        <div style={{ marginLeft: rhythm(1.25) }}>Contact</div>
      </div> */}
    </div>
  </div>
);
