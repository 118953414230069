import * as React from "react";
import { css } from "emotion";

export const Loading: React.SFC<{}> = () => (
  <div
    className={css`
      border: 2px solid #f3f3f3;
      border-top: 2px solid #3498db;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      animation: spin 1s linear infinite;

      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    `}
  />
);
