import * as React from "react";
import { css } from "emotion";
import { withFormik, InjectedFormikProps, Form } from "formik";

import { rhythm } from "../utils/typography";
import { Icon } from "./Icon";
import { Loading } from "./Loading";

interface FormValues {
  email: string;
  buttonState: "" | "loading" | "success";
}

class C extends React.PureComponent<InjectedFormikProps<{}, FormValues>> {
  render() {
    const { values, handleChange, handleBlur, isSubmitting } = this.props;

    let buttonBody: any = "NOTIFY ME";

    if (values.buttonState === "loading") {
      buttonBody = <Loading />;
    } else if (values.buttonState === "success") {
      buttonBody = <Icon name="check" fill="#977535" width={20} height={20} />;
    }

    return (
      <Form
        className={css`
          position: sticky;
          margin-left: auto;
          max-width: 220px;
          @media only screen and (max-width: 960px) {
            margin-left: 0px;
            margin-right: auto;
            position: inherit;
            margin-left: calc((100vw - 3.2rem - 600px) / 2);
          }
          @media only screen and (max-width: 767px) {
            margin-left: 0;
            max-width: 100%;
          }
        `}
        style={{
          width: "100%",
          top: rhythm(8),
          maxHeight: 235,
          backgroundColor: "#C6A15B",
          padding: rhythm(1 / 2),
          paddingTop: rhythm(3 / 4)
        }}
      >
        <h5
          style={{
            color: "#F3E3C5",
            fontWeight: 700,
            fontSize: 24,
            marginBottom: rhythm(3 / 4)
          }}
        >
          {values.buttonState === "success"
            ? "Successfully added to beta list."
            : "Get notified when Saffron’s in beta."}
        </h5>
        <div>
          <div
            style={{
              marginBottom: rhythm(1 / 4),
              position: "relative"
            }}
          >
            {values.buttonState === "success" ? (
              <div style={{ height: 46, width: "100%" }} />
            ) : (
              <>
                <input
                  required
                  type="email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={css`
                    border: 1px solid #f3e3c5;
                    width: 100%;
                    height: 46px;
                    padding-left: 32px;
                    padding-top: ${rhythm(3 / 8)};
                    padding-bottom: ${rhythm(3 / 8)};
                    font-size: 14px;
                    background: #d7be8e;
                    color: #333;
                    border-radius: 3px;
                    &:focus {
                      outline: 0;
                    }
                  `}
                />
                <Icon
                  className={css`
                    top: 12px;
                    position: absolute;
                    left: 5px;
                  `}
                  fill="#977535"
                  name="email"
                  height={22}
                  width={22}
                />
              </>
            )}
          </div>
          <button
            type="submit"
            disabled={isSubmitting}
            className={css`
              border: none;
              border-radius: 3px;
              width: 100%;
              letter-spacing: 0.5px;
              line-height: 18px;
              background: #ffffff;
              padding-top: ${rhythm(3 / 8)};
              padding-bottom: ${rhythm(3 / 8)};
              padding-left: auto;
              padding-right: auto;
              color: #977535;
              font-size: 14px;
              cursor: pointer;
              display: flex;
              align-items: center;
              justify-content: center;
              &:hover {
                background-color: #977535;
                color: #fff;
              }
            `}
          >
            {buttonBody}
          </button>
        </div>
      </Form>
    );
  }
}

export const Sidebar = withFormik<{}, FormValues>({
  displayName: "Sidebar",
  mapPropsToValues: () => ({ buttonState: "", email: "" }),
  handleSubmit: async ({ email }, { setFieldValue }) => {
    setFieldValue("buttonState", "loading");
    try {
      await fetch(
        "https://tq7r7ba4k6.execute-api.us-east-1.amazonaws.com/prod/save-email",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ email })
        }
      );
    } catch (err) {
      console.log(err);
    }
    setFieldValue("buttonState", "success");
  }
})(C);
