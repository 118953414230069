import Typography from "typography";

const typography = new Typography({
  title: "saffron",
  baseFontSize: "18px",
  baseLineHeight: 1.6,
  scaleRatio: 1.25,
  googleFonts: [
    {
      name: "Muli",
      styles: ["300", "400", "700", "900"]
    },
    {
      name: "Oswald",
      styles: ["300", "400", "600"]
    },
    {
      name: "SourceSansPro",
      styles: ["600"]
    }
  ],
  headerFontFamily: ["Muli", "Helvetica nue", "sans-serif"],
  bodyFontFamily: ["Muli", "Helvetica nue", "sans-serif"],
  headerColor: "#0d0d0d",
  bodyColor: "#0d0d0d",
  headerWeight: "900",
  bodyWeight: "400",
  includeNormalize: true,
  // tslint:disable-next-line:no-shadowed-variable
  overrideStyles: ({ adjustFontSizeTo }: any) => ({
    button: {
      fontFamily: `"Source Sans Pro", "Arial", "sans-serif"`,
      fontWeight: "600"
    },
    input: {
      fontFamily: `"Source Sans Pro", "Arial", "sans-serif"`
    }
  })
});

export default typography;
export const rhythm = typography.rhythm;
export const scale = typography.scale;
export const options = typography.options;
