import * as React from "react";

import * as icons from "./icons";

export type PossibleIcons = keyof (typeof icons);

interface IconProps extends React.SVGProps<SVGSVGElement> {
  name: PossibleIcons;
  style?: React.CSSProperties;
  css?: string;
}

export const Icon: React.SFC<IconProps> = ({ name, ...other }) => {
  return (
    <svg {...other} viewBox={"0 0 20 20"}>
      <path d={icons[name]} />
    </svg>
  );
};
