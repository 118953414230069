import * as React from "react";
import { Link } from "gatsby";
import { css } from "emotion";

import { rhythm } from "../utils/typography";
import headerImage from "../images/header.jpg";
import { PotLogo } from "./Logo";

export const Header = () => (
  <div
    style={{
      width: "100%",
      height: "100px",
      position: "fixed",
      top: 0,
      zIndex: 999,
      backgroundImage: `url(${headerImage})`,
      backgroundRepeat: "repeat-x",
      backgroundPosition: "center",
      display: "flex",
      alignItems: "center"
    }}
  >
    <div
      className={css`
        @media only screen and (max-width: 425px) {
          display: flex;
          justify-content: center;
        }
      `}
      style={{
        paddingLeft: rhythm(1),
        paddingRight: rhythm(1),
        margin: "auto",
        width: 940
      }}
    >
      <Link to="/" style={{ display: "flex" }}>
        <PotLogo />
      </Link>
    </div>
  </div>
);
