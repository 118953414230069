import * as React from "react";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";
import { css } from "emotion";

import { Header } from "./header";
import { rhythm } from "../utils/typography";
import { Footer } from "./Footer";
import { Sidebar } from "./Sidebar";

export const Layout: React.SFC<{}> = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <div
        style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
      >
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { name: "description", content: "Podcast for Saffron" },
            { name: "keywords", content: "saffron, podcast, my saffron app" }
          ]}
        >
          <html lang="en" />
        </Helmet>
        <Header />
        <div
          className={css`
            @media only screen and (max-width: 960px) {
              flex-direction: column;
              align-items: center;
            }
          `}
          style={{
            display: "flex",
            flex: 1,
            width: "100%",
            maxWidth: 960,
            margin: "auto",
            marginTop: rhythm(5),
            paddingLeft: rhythm(1),
            paddingRight: rhythm(1)
          }}
        >
          <div
            className={css`
              max-width: 600px;
              @media only screen and (max-width: 600px) {
                max-width: 100%;
              }
            `}
            style={{
              marginBottom: rhythm(2),
              display: "flex",
              flexDirection: "column"
            }}
          >
            {children}
          </div>
          <Sidebar />
        </div>
        <Footer />
      </div>
    )}
  />
);
