import * as React from "react";
import { Link } from "gatsby";
import { css } from "emotion";
import day from "dayjs";

import { EpisodeNumber } from "./EpisodeNumber";
import { rhythm } from "../utils/typography";
import { Icon } from "./Icon";

interface Props {
  node: {
    id: string;
    fields: {
      slug: string;
      shortDescription: string;
    };
    description?: string;
    episodeNumber: string;
    title: string;
    time: string;
    date: string;
  };
}

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

export class PodcastPreview extends React.PureComponent<Props> {
  render() {
    const { node } = this.props;

    const date = day(node.date);
    const dateStr = date.format("MMMM D, YYYY");

    return (
      <div key={node.id}>
        <Link
          to={node.fields.slug}
          className={css`
            text-decoration: none;
            color: inherit;
          `}
        >
          <EpisodeNumber>{`EP${node.episodeNumber}`}</EpisodeNumber>
          <h2 style={{ marginBottom: 0 }}>{node.title}</h2>
        </Link>
        <p style={{ marginBottom: rhythm(1 / 2) }}>
          {node.description || node.fields.shortDescription}
        </p>
        <div
          className={css`
            align-items: center;
            @media only screen and (max-width: 600px) {
              flex-direction: column;
              align-items: flex-start;
            }
          `}
          style={{ display: "flex" }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <Icon name="clock" width={20} height={20} />
            <div style={{ marginLeft: rhythm(1 / 3) }}>{node.time}</div>
          </div>
          <div
            className={css`
              margin-left: ${rhythm(1)};
              @media only screen and (max-width: 600px) {
                margin-left: 0;
              }
            `}
          >
            {dateStr}
          </div>
        </div>
      </div>
    );
  }
}
