import * as React from "react";

export const PotLogo = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    id="mark"
    width="150px"
    height="65px"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1230.6 408"
    {...props}
  >
    <path
      fill="#E6E6E6"
      d="M311.8 272.8c-29.2 0-51.3-9.1-51.3-21.2 0-9.2 6-17.3 13.2-25.2 12.4 9.2 39.2 26.2 68.1 26.2 23.9 0 38.8-12.6 38.8-32.9 0-27.3-21.2-46.2-41.6-64.5-17.2-15.4-33.4-29.8-33.4-47.1 0-29.8 41.2-59.1 83.3-59.1 37.6 0 37.6 12.6 37.6 16.7 0 7.6-7.4 18.7-13.5 24.2-18.1-14.1-35.8-21.8-50.2-21.8C347.1 67.9 337 77 337 91c0 20.5 18 36.2 37.1 52.9 19 16.6 38.6 33.7 38.6 55.4 0 30.9-35.1 73.5-100.9 73.5zm119.5-27.3c0-47 67.2-108.1 121.3-115.1 3.3-8.3 16.9-12.8 26-12.8 5.4 0 8.7 1.6 7.4 4.5-11.1 26-40.8 98.2-40.8 113.5 0 4.1 2.1 7 5.8 7 8.3 0 16.9-12.4 23.5-25.6 4.1 1.2 8.3 4.1 5.4 10.3-10.3 21-29.7 45.4-52.8 45.4-8.7 0-12-4.5-12-12 0-11.1 10.3-36.3 15.7-50.8h-.8c-23.1 35.1-48.3 62.7-75.9 62.7-17 .1-22.8-12.3-22.8-27.1zm105.6-66.4c3.3-9.9 7-19.8 10.7-31.8-49.9 5-87.9 51.2-87.9 86.6 0 7 4.1 12 11.1 12 18.7 0 50-40 66.1-66.8z"
    />
    <path
      fill="#E6E6E6"
      d="M514.6 396.1c0-6.6 2.9-14 5.8-19.8 9.9 2.9 19.8 4.9 31.8 4.9 24.8 0 42.5-14 60.2-86.2l34.7-141.1h-39.2c-3.7 0-5.4-.8-5.4-2.5 0-5 18.2-15.7 39.6-15.7h9.5c20.2-80.5 55.3-119.2 108.9-119.2 12.4 0 27.2 3.7 35.5 8.3C812.9 9.1 833.1 0 857.5 0c20.2 0 38.4 8.3 38.4 16.9 0 8.2-9.1 18.6-14.4 22.7-13.2-8.7-31.4-16.5-47.9-16.5-39.2 0-55.3 51.6-68.1 112.6h49.9c2.5 0 3.7 1.2 3.7 2.9 0 4.9-9.5 15.3-17.3 15.3h-40.4l-23.9 114.3c-16.1 76.7-69.7 123.4-105.2 123.4-10.7 0-14.9-3.3-14.9-12 0-6.6 2.9-14 5.8-19.8 9.9 3.3 18.2 4.1 26.8 4.1 24.8 0 38.4-13.2 56.1-85.4l30.5-124.6h-64.8l-28.1 130.8C627.3 361.4 570.7 408 532 408c-14.5 0-17.4-4.1-17.4-11.9zm226.5-260.4c8.7-34.7 20.2-64.8 35.5-87.9-10.7-5-23.1-8.3-36.7-8.3-40.8 0-51.2 35.1-63.9 96.1h65.1zm81.3 132.5c12.4-26.8 32.2-68.9 32.2-99.8 0-4.9 0-17.3-9.1-17.3-11.5 0-21.9 13.6-28.5 27.2-4.1-1.2-7.8-3.7-4.5-9.9 9.1-16.9 26.4-41.7 51.2-41.7 11.6 0 16.9 7.4 16.9 21 0 8.3-1.2 15.3-3.7 25.6h.8c20.6-37.1 38.4-46.6 53.2-46.6 8.2 0 12.4 5 12.4 10.7 0 6.6-2.9 15.3-8.7 23.9-8.2-3.7-12.8-5.8-20.6-5.8-17.3 0-35.5 37.5-51.2 71.4-2.9 10.3-5.8 21.5-8.3 32.6-1.6 8.7-16.1 13.2-25.2 13.2-4.8 0-8.5-1.2-6.9-4.5zm117.2-36.3c0-49.9 33.4-105.2 82.9-105.2 24.8 0 39.2 14.9 39.2 40.8 0 49.9-33.4 105.2-82.9 105.2-24.8 0-39.2-14.8-39.2-40.8zm93.6-57c0-20.2-7-33-21.9-33-28.9 0-43.3 45-43.3 82.5 0 20.2 7 33 21.9 33 28.9 0 43.3-44.9 43.3-82.5zm47.9 93.3c9.5-22.3 39.2-87.5 39.2-109.7 0-2.9-1.2-7.4-6.6-7.4-7.4 0-19 13.6-25.6 27.2-4.1-1.2-7.8-3.7-4.5-9.9 9.1-16.9 28.5-41.7 53.2-41.7 7.8 0 12.4 3.7 12.4 12 0 3.3-.4 7-2.1 11.5l-13.2 38.4h.8c28.1-42.1 51.6-61.9 73.4-61.9 9.1 0 15.3 5.8 15.3 16.5 0 19-28.5 76.3-28.5 92.4 0 3.7 1.6 7 5.8 7 8.7 0 16.9-12.4 23.5-25.6 4.1 1.2 8.3 4.1 5.4 10.3-10.3 21-29.7 45.4-52.8 45.4-9.5 0-13.2-6.2-13.2-15.3 0-26.8 30.9-82.1 30.9-97 0-4.1-2.1-7-7-7-14.4 0-50.7 47-68.9 82.5-2.5 8.3-4.5 16.1-6.2 23.5-2.1 8.7-15.7 13.2-24.3 13.2-5 .1-8.3-1.1-7-4.4z"
    />
    <circle fill="#531410" cx="111.7" cy="160.4" r="96.9" />
    <path
      d="M149.5 174.3c-2.7 3.8-7.7 8.8-15.2 11.8-3.7 1.5-8 2.5-12.6 2.6-4.7.2-9.9-1-14.5-3.4-9.3-4.8-16.1-15.6-15.2-27 .4-5.8 3-11.4 7.2-15.3 4.1-3.9 9.8-6.2 15.3-6.2 5.7-.1 11.1 2.6 14.4 6.7 3.3 4.2 4.6 9.5 3.6 14.3-1.1 4.9-4.7 8.6-8.7 10-4 1.5-8.5 1-11.4-1.3-3-2.2-4.3-5.5-4.3-8.3-.1-2.9 1.3-5.1 2.7-6.4 3.1-2.6 5.6-2 5.5-2.1.1.1-2.5-.2-5.1 2.5-1.2 1.3-2.3 3.5-1.9 6 .3 2.5 1.7 5.2 4.3 6.7 2.5 1.6 6.1 1.7 9.1.2 3.1-1.4 5.5-4.5 6-8.1.4-3.5-.9-7.6-3.5-10.3-2.7-2.7-6.5-4.3-10.3-3.8-8.1.6-14.6 8.1-14.2 15.5.1 7.8 5.3 14.7 11.4 17.3 3.1 1.3 6.3 1.8 9.3 1.5 3.2-.2 6-1.1 8.4-2.2 4.9-2.3 8-5.7 9.8-8.2 1.8-2.5 4.8-7.3 4.8-14.5 0-7.3-2.9-17-10.9-24-3.9-3.6-9-6.2-14.7-7.4-5.6-1.2-12.1-.8-18.2.8-12.4 3.2-23.5 13-28.6 25.4-5.1 12.4-3.7 26.9 1.1 38.5 4.9 11.7 13.4 20.6 21.9 26 8.5 5.5 16.8 7.7 22.6 8.6 2.9.5 5.2.6 6.8.6 1.6.1 2.4.1 2.4.1h-2.4c-1.6 0-3.9.1-6.8-.3-5.9-.5-14.4-2.3-23.6-7.4-9.1-5.1-18.5-13.9-24.4-26.1-3-6.1-4.9-13-5.7-20.2-.8-7.2-.1-14.9 2.4-22.2 5-14.5 17.3-26.7 32-31.3 7.3-2.3 15-3.3 22.5-2.1 7.4 1.3 14.3 4.4 19.7 8.8 11.1 8.7 15.8 21.7 16.1 31.8-.1 11-4.4 18.7-7.1 22.4z"
      fill="#E6E6E6"
    />
    <path
      d="M200.2 91.5c3.9-17.4 5.8-31 3.5-34.1-10.9-13-27.6-22.7-46.1-26.9-3.8-.6-14.7 7.6-28 19.4C85.3 42.9 39.3 63 15.3 104c-24.1 41.2-18.8 91.4 9.3 126.5-3.7 16.9-5.5 30.1-3.5 33.5 7 11.8 32.2 26.8 46.1 26.9 4.1 0 14.6-8.2 27.5-19.8 44.1 6.9 90-13.3 113.9-54.2 23.9-40.7 19-90.3-8.4-125.4zm-44.4-44.4c2-3.5 6.5-4.6 9.9-2.6l20.9 12.2c3.5 2 4.6 6.5 2.6 9.9-2 3.5-6.5 4.6-9.9 2.6L158.4 57c-3.5-2-4.6-6.5-2.6-9.9zM68.1 273.2c-2 3.5-6.5 4.6-9.9 2.6l-20.9-12.2c-3.5-2-4.6-6.5-2.6-9.9 2-3.5 6.5-4.6 9.9-2.6l20.9 12.2c3.5 1.9 4.7 6.4 2.6 9.9zm43.4-16c-53.3-.3-96.2-43.7-96-96.9.3-53.3 43.7-96.2 96.9-96s96.2 43.7 96 96.9c-.2 53.3-43.6 96.3-96.9 96z"
      fill="#d13c1e"
    />
  </svg>
);
